//Copyright by Chaafo pvt ltd
//QuickSnack is designed to show menu items in dropdown along with their catgeories


import React, { useState, useContext, useEffect } from 'react';
import { Image, Badge, Button, Media } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Icofont from 'react-icofont';
import { GlobalContext } from '../../components/GlobalState';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MERCHANTKEY } from '../../App';
import { Link } from 'react-router-dom';
import { urls } from '../URLs';
import GlobalContext_ from '../../GlobalContext';
import DetailModalAnlook from './innerComponents/DetailModalAnlook';

function QuickSnack(props) {
  const { MK } = useContext(GlobalContext_);

  const { qty, size, cart } = props;

  var T = localStorage.getItem("tokenn");
  const [quantity, setQuantity] = useState(qty);
  const [max] = useState(props.maxValue);
  const [min] = useState(props.minValue || 0);
  const [showDetailmodal, setShowDetailmodal] = useState(false);
  const [price, setPrice] = useState([]);
const [isExpanded, setIsExpanded] = useState(false);

  const hideDetailmodal = () => setShowDetailmodal(false);

  const { setCart, setTotal, setCount, setEmpty, setPay } =
    useContext(GlobalContext);

  useEffect(() => {
    setQuantity(qty || 0);
  }, [qty]);

  const sortCart = (data) => {
    data.sort((a, b) => {
      if (a.sort_id > b.sort_id) {
        return 1;
      } else if (b.sort_id > a.sort_id) {
        return -1;
      } else {
        return 0;
      }
    });
    return data;
  };

  toast.configure();

  //retrieving the total  Qtr. + Half + full
  const getTotalQuantity = (itemId) => {
    
    return cart
      ?.filter((item) => item.item_id == itemId)
      .reduce((total, item) => total + Number(item.quantity), 0);
  };
  // console.table(cart);
  console.table(props.id);
  const totalQuantity = getTotalQuantity(props.id);

  // funtion to load item details
  const Itemdetails = () => {
    var myHeaders = new Headers();
    myHeaders.append("Cookie", "_lang=en");

    var formdata = new FormData();
    formdata.append("item_id", props.id);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };
    fetch(urls.ItemDetails + "?merchant_keys=" + MK, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setPrice(result.details.data.prices);
      });
    localStorage.setItem("id", props.id);
  };

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className={"p-3 border-bottom " + props.itemClass} >
      <DetailModalAnlook show={showDetailmodal} onHide={hideDetailmodal} quantity={totalQuantity} cart={cart} refresh={props.refresh}/>
      {quantity <= 0 ?
       <span
                       className="float-right"
                       onClick={() => setShowDetailmodal(true)}
                     >
                       <Button
                         variant="outline-secondary"
                         onClick={()=>Itemdetails()}
                         size="sm"
                       >
                         ADD
                       </Button>
                     </span>
        :
        <span className="count-number float-right" onClick={() => setShowDetailmodal(true)}>
          <Button variant="outline-secondary" onClick={()=>Itemdetails()} className="btn-sm left dec"> <Icofont icon="minus" /> </Button>
          <input className="count-number-input" type="text" value={totalQuantity} readOnly />
          <Button variant="outline-secondary" onClick={()=>Itemdetails()} className="btn-sm right inc"> <Icofont icon="icofont-plus" /> </Button>
        </span>
      }
      <Media>
        {props.image ?
          <Image className={"mr-2 pr-2" + props.imageClass} src={props.image} alt={props.imageAlt} style={{height: '4rem', width: '4rem'}} loading="lazy"/>
          :
          <div className="mr-3"><Icofont icon="ui-press" className={"text-danger1 food-item"} /></div>
        }
        <Media.Body>
        <div className='d-flex gap-1 align-items-center' >
        <img src={props.vegNonvegTag} alt='' loading="lazy" style={{height: '1rem'}}/>
        <Link className="mb-1 text-black" onClick={()=>Itemdetails()} >{props.title} {props.showBadge ? <Badge variant={props.badgeVariant}>{props.badgeText}</Badge> : ""}</Link>
        </div>
        <p className="text-gray mb-0 py-1" style={{cursor: 'pointer'}} title={props.subTitle} >
            {isExpanded ? props.subTitle : `${props.subTitle?.slice(0, 50)}...`}
            {props.subTitle && props.subTitle.length > 50 && (
              <span className="text-primary cursor-pointer" onClick={toggleExpand}>
                {isExpanded ? " See Less" : " See More"}
              </span>
            )}
          </p>
          <p className="text-gray mb-0">{props.priceUnit}{props.price}</p>
        </Media.Body>
      </Media>
    </div>

  );
}

export default QuickSnack;
