import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
// import { scrollToSection } from 'some-module';
import { Link } from "react-router-dom";
import ItemSuggestions from "./ItemSuggestions";
import BestSeller from "./BestSeller";
import QuickSnack from "./QuickSnack";
import Spotlight from "../Spotlight";
// import { ScrollProvider } from "../../ScrollContext";
import { urls } from "../URLs";
import { Row, Col, Form, InputGroup, Button } from "react-bootstrap";
import Icofont from "react-icofont";
import { FaChevronDown, FaChevronUp } from "react-icons/fa6";
import { TiArrowSortedDown } from "react-icons/ti";

import GlobalContext_ from "../../GlobalContext";
import { GlobalContext } from "../GlobalState";
import { ScrollContext } from "../../ScrollContext";

import { LuShoppingCart } from "react-icons/lu";
import { CardColors, SiteColors } from "../../constants/colorTheme";

function AnlookWhatsappOrdering() {
  const { restaurantinfo, MK, items, recommended, category, cat_n1 } =
    useContext(GlobalContext_);

  const [search, setSearch] = useState(null);
  const [openItems, setOpenItems] = useState({});
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  var ORDER_ID = localStorage.getItem("ORDER_ID");

  const [anlookOrderData, setAnlookOrderData] = useState([]);
  const [error, setError] = useState(null);
  //Function to search the item from menu
  let cancelToken;
  const params = new URLSearchParams(window.location.search);

  const TotalItemsInTheCart = anlookOrderData?.reduce(
    (total, item) => total + Number(item.quantity),
    0
  );

  const Search = async (key) => {
    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel("canceled");
    }

    cancelToken = axios.CancelToken.source();

    var data = new FormData();
    data.append("item_name", key);

    var config = {
      method: "post",
      url: urls.SearchItem + "?merchant_keys=" + MK,
      cancelToken: cancelToken.token,
      data: data,
    };

    try {
      await axios(config).then((resp) => {
        if (resp.data.code == 1) {
          setSearch(resp.data.details.list);
        } else {
          setSearch(null);
        }
      });
    } catch (error) {}
  };

  //Function to set the ratings star value
  const getStarValue = ({ value }) => {
    // setRate_f(value);
  };

  const getQty = ({ id, quantity }) => {};

  const AccordianControl = (cat_id) => {
    setOpenItems({ ...openItems, [cat_id]: !openItems[cat_id] });
  };
  const fetchOrderData = async () => {
    try {
      const response = await fetch(
        "https://payin.shreedevs.com/api/Order/getReceiptPrintDataV2",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ orderid: params.get("orderid") }),
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const data = await response.json();
      setAnlookOrderData(data.response[0].items);
    } catch (err) {
      setError(err.message);
    }
  };
  useEffect(() => {
    fetchOrderData();
  }, []);

  return (
    <GlobalContext.Consumer>
      {(state) => (
        <>
          <div style={{ margin: "1rem" }}>
            {/* NavBar */}
            <div
              style={{
                alignItems: "center",
                borderRadius: "5px",
              }}
              className="bg-light my-4 p-4 gap-3 d-flex flex-column"
            >
              <div
                className="d-flex justify-content-between align-items-start"
                style={{ width: "100%" }}
              >
                <img
                  src={restaurantinfo?.merchant_image}
                  alt=""
                  loading="lazy"
                  style={{ width: "50px", height: "50px", objectFit: "cover" }}
                />

                <Link
                  to={`/anlook/whatsappCheckout?mobile=${params.get(
                    "mobile"
                  )}&userid=${params.get("userid")}&orderid=${params.get(
                    "orderid"
                  )}`}
                  style={{
                    border: "1rem solid white",
                    margin: "-2.5rem",
                    borderRadius: "10px",
                  }}
                  className="bg-light p-3"
                >
                  <LuShoppingCart
                    style={{ height: "30px", width: "auto", color: "green" }}
                  />
                </Link>
              </div>

              <div
                style={{
                  justifyContent: "space-between",
                  width: "100%",
                }}
                className="d-flex align-items-center"
              >
                <div className="d-flex align-items-start flex-column gap-3">
                  <h6 className="d-flex mb-0">No. of Items:</h6>
                  <h6 className="d-flex mb-0">Total Price:</h6>
                </div>
                <div
                  style={{ textAlign: "right" }}
                  className="d-flex align-items-end flex-column gap-3"
                >
                  <h6 className="d-flex mb-0">
                    {anlookOrderData?.reduce(
                      (total, item) => total + Number(item.quantity),
                      0
                    )}
                  </h6>
                  <h6 className="d-flex mb-0">
                    ₹
                    {anlookOrderData?.reduce(
                      (total, item) => total + Number(item.total_price),
                      0
                    )}
                  </h6>
                </div>
              </div>
            </div>

            {/* SearchBar */}
            <Form className="explore-outlets-search mb-4">
              <InputGroup>
                <Form.Control
                  type="text"
                  onChange={(event) => Search(event.target.value)}
                  placeholder="Search for dishes..."
                />

                <InputGroup.Append>
                  <Button
                    type="button"
                    variant="link"
                    style={{ border: "1px solid lightgray" }}
                  >
                    <Icofont icon="search" />
                  </Button>
                </InputGroup.Append>
                <InputGroup.Append
                  onClick={() => setDropdownOpen(!isDropdownOpen)}
                >
                  <Button
                    type="button"
                    variant="link"
                    style={{ border: "1px solid lightgray", padding: 0 }}
                  >
                    <div style={{ fontSize: "2rem", padding: 0, margin: 0 }}>
                      <TiArrowSortedDown />
                    </div>
                  </Button>
                </InputGroup.Append>
                {isDropdownOpen && (
                  <div
                    style={{
                      width: "50%",
                      height: "20rem",
                      boxShadow: "0 0 5px lightgrey",
                      zIndex: 999,
                      overflow: "scroll",
                      position: "absolute",
                      background: "white",
                      right: 0,
                    }}
                    className="my-5"
                  >
                    {items?.map((item, index) => (
                      <div
                        className="p-2 m-1"
                        style={{ borderBottom: "1px solid lightGrey" }}
                        onClick={() => {
                          setDropdownOpen(!isDropdownOpen);
                          AccordianControl(item.cat_id);

                          // Scroll to the element with the given ID
                          const element = document.getElementById(item.cat_id);
                          if (element) {
                            element.scrollIntoView({
                              behavior: "smooth",
                              block: "start",
                            });
                          }
                        }}
                      >
                        {item.category_name}
                      </div>
                    ))}
                  </div>
                )}
              </InputGroup>
              <div>
                {search ? (
                  <div className="mt-4">
                    {
                      <Row>
                        {search?.map((item, Index) => (
                          <Col md={4} sm={6} className="mb-4">
                            <ItemSuggestions
                              cart={anlookOrderData}
                              refresh={fetchOrderData}
                              vegNonvegTag={item.icon_dish[0]}
                              qty={
                                anlookOrderData?.find(
                                  (cartItem) => cartItem.item_id == item.item_id
                                )?.quantity ?? 0
                              }
                              id={item?.item_id}
                              title={item?.item_name}
                              subTitle={
                                item.item_description ||
                                "Indian • American • Italian"
                              }
                              imageAlt={item.photo}
                              image={item.photo_url}
                              imageClass="imgheight"
                              price={Number(item.prices.price)}
                              size={item.prices.size}
                              discountprice={Number(item.prices.discount_price)}
                              priceUnit="₹ "
                              isNew={true}
                              //showPromoted={true}
                              promotedVariant="dark"
                              favIcoIconColor="text-danger"
                              // rating='3.1 (300+)'
                              getValue={getQty}
                              Index={Index}
                              category_id={category}
                            />
                          </Col>
                        ))}
                      </Row>
                    }
                  </div>
                ) : (
                  " "
                )}
              </div>
            </Form>

            {/* Recommended */}
            <Row>
              <h5 className="mb-4 mt-3 col-md-12">Recommended</h5>
              {recommended?.map((recommend, Index) => (
                <Col md={4} sm={6} className="mb-4">
                  {console.log(recommend.icon_dish[0], "find icon_dish 280")}
                  <BestSeller
                    cart={anlookOrderData}
                    vegNonvegTag={recommend.icon_dish[0]}
                    refresh={fetchOrderData}
                    qty={
                      anlookOrderData?.find(
                        (cartItem) => cartItem.item_id == recommend.item_id
                      )?.quantity ?? 0
                    }
                    id={recommend?.item_id}
                    title={recommend?.item_name}
                    subTitle={
                      recommend.item_description ||
                      "Indian • American • Italian"
                    }
                    imageAlt={recommend.photo}
                    image={recommend.photo}
                    imageClass="imgheight"
                    price={Number(recommend.prices.price)}
                    size={recommend.prices.size}
                    discountprice={Number(recommend.prices.discount_price)}
                    priceUnit="₹ "
                    isNew={true}
                    //showPromoted={true}
                    promotedVariant="dark"
                    favIcoIconColor="text-danger"
                    // rating='3.1 (300+)'
                    getValue={getQty}
                    Index={Index}
                    category_id={category}
                  />
                </Col>
              ))}
            </Row>
            {/* Dropdown Section  */}
            {/* Categories */}
            <div>
              <Row>
                {items
                  ? items.map((item, itemIndex) => (
                      <Col md={12} key={itemIndex}>
                        <div
                          className=""
                          style={{
                            marginBottom: "1rem",
                            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                            borderRadius: "5px",
                          }}
                        >
                          <div
                            id={item.cat_id}
                            className="d-flex justify-content-between"
                            style={{
                              padding: "1rem",
                              borderBottom: "1px solid lightgrey",
                            }}
                            onClick={() => AccordianControl(item.cat_id)}
                          >
                            <h2
                              className="m-0"
                              style={{ fontSize: "1rem", fontWeight: "bold" }}
                            >
                              {item.category_name}
                            </h2>
                            {openItems[item?.cat_id] ? (
                              <FaChevronUp />
                            ) : (
                              <FaChevronDown />
                            )}
                          </div>
                          {openItems[item.cat_id] && ( // Only render content if open
                            <div>
                              {item.menu_items?.map((menu, Index) => (
                                <QuickSnack
                                  cart={anlookOrderData}
                                  refresh={fetchOrderData}
                                  vegNonvegTag={menu.icon_dish[0]}
                                  qty={
                                    anlookOrderData?.find(
                                      (cartItem) =>
                                        cartItem.item_id == menu.item_id
                                    )?.quantity ?? 0
                                  }
                                  id={menu?.item_id}
                                  title={menu?.item_name}
                                  subTitle={
                                    menu.item_description ||
                                    "Indian • American • Italian"
                                  }
                                  imageAlt={menu.photo}
                                  image={menu.photo}
                                  imageClass="imgheight"
                                  price={Number(menu.prices.price)}
                                  size={menu.prices.size}
                                  discountprice={Number(
                                    menu.prices.discount_price
                                  )}
                                  priceUnit="₹ "
                                  isNew={true}
                                  //showPromoted={true}
                                  promotedVariant="dark"
                                  favIcoIconColor="text-danger"
                                  // rating='3.1 (300+)'
                                  getValue={getQty}
                                  Index={Index}
                                  category_id={category}
                                  cat_id={item.cat_id}
                                />
                              ))}
                            </div>
                          )}
                        </div>
                      </Col>
                    ))
                  : ""}
              </Row>
            </div>
            {anlookOrderData.length ? (
              <Link
                to={`/anlook/whatsappCheckout?mobile=${params.get(
                  "mobile"
                )}&userid=${params.get("userid")}&orderid=${params.get(
                  "orderid"
                )}`}
                style={{
                  background: CardColors.buttonPrimary,
                  position: "fixed",
                  bottom: "1rem",
                  width: "93%",
                }}
                className="btn btn-block btn-lg text-white"
              >
                {TotalItemsInTheCart > 1
                  ? (TotalItemsInTheCart > 5 ? "5+" : TotalItemsInTheCart) +
                    " items added"
                  : TotalItemsInTheCart + " item added"}

                <Icofont icon="long-arrow-right" />
              </Link>
            ) : null}
          </div>
        </>
      )}
    </GlobalContext.Consumer>
  );
}

export default AnlookWhatsappOrdering;
