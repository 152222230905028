//Copyright by Chaafo pvt ltd
//Quickbite is designed to show menu items in dropdown along with their catgeories


import React, { useState, useContext, useEffect } from 'react';
import { Image, Badge, Button, Media } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Icofont from 'react-icofont';
import { GlobalContext } from '../../components/GlobalState';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MERCHANTKEY } from '../../App';
import Detailmodal from '../modals/Detailmodal';
import { Link } from 'react-router-dom';
import { urls } from '../URLs';
import GlobalContext_ from '../../GlobalContext';

function QuickBite(props) {

  const { MK } = useContext(GlobalContext_);
  var T = localStorage.getItem("tokenn");

  const { qty, size, state } = props;
  const [quantity, setQuantity] = useState(qty)
  const [show] = useState(props.show || true)
  const [max] = useState(props.maxValue)
  const [min] = useState(props.minValue || 0)
  const [showDetailmodal, setShowDetailmodal] = useState(false);
  const [price, setPrice] = useState([]);
  const hideDetailmodal = () => setShowDetailmodal(false);

  const { cart, setCart, setTotal, setCount, setEmpty, setPay } = useContext(GlobalContext);

  useEffect(() => {
    setQuantity(qty || 0);
  }, [qty])

  const sortCart = (data) => {
    data.sort((a, b) => {
      if (a.sort_id > b.sort_id) {
        return 1;
      }
      else if (b.sort_id > a.sort_id) {
        return -1;
      }
      else {
        return 0;
      }

    })
    return data;

  }

  toast.configure();

  // retrieving the total  Qtr. + Half + full 
  const getTotalQuantity = (itemId) => {

    return cart?.filter(item => item.item_id == itemId) //itemId is not equal so we are getting 0. Please check that you are passing correct item id 
      .reduce((total, item) => total + item.qty, 0);

  };
  const totalQuantity = getTotalQuantity(props.id);

  //funtion to call webloadcart api and renders the cart on time.
  //if user is logged in, that means token is there, therefore if case will be executed. Otherwise, else case will be executed.
  async function Loadcart() {

    if (T) {
      var myHeaders = new Headers();
      myHeaders.append("Cookie", "_lang=en");
      var formdata = new FormData();
      formdata.append("device_id", T);
      formdata.append("token", T)
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
      }
      fetch(urls.LoadCart + "?merchant_keys=" + MK, requestOptions)
        .then(response => response.json())
        .then(result => {
          if (result.code === 1) {
            setCart(result.details.data.item);
            setTotal(result.details.data.total);
            setCount(result.details.cart_details);
            setEmpty('');
            setPay(result.details);

          }
        })
    }
    else {
      const raw = localStorage.getItem('cart');
      const cartFetchedFromLocalStorage = JSON.parse(raw);
      setCart(sortCart(cartFetchedFromLocalStorage));

      const tRaw = localStorage.getItem('total');
      const totalFetchedFromLocalStorage = JSON.parse(tRaw);
      setTotal(totalFetchedFromLocalStorage);

      const cRaw = localStorage.getItem('Count');
      const countFetchedFromLocalStorage = JSON.parse(cRaw);
      setCount(countFetchedFromLocalStorage);

      const eRaw = localStorage.getItem('empty');
      const EmptyFetchedFromLocalStorage = JSON.parse(eRaw);
      setEmpty(EmptyFetchedFromLocalStorage);

      const pRaw = localStorage.getItem('pay');
      const payFetchedFromLocalStorage = JSON.parse(pRaw);
      setPay(payFetchedFromLocalStorage);

    }
  }

  // Function to show item details
  const addhandler = async () => {

    var myHeaders = new Headers();
    myHeaders.append("Cookie", "_lang=en");

    var formdata = new FormData();
    formdata.append("item_id", props.id);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    const res = await fetch(urls.ItemDetails + "?merchant_keys=" + MK, requestOptions);
    const raw = await res.json();
    if (raw.details.data.prices.length >= 1) {
      localStorage.setItem("id", props.id);
      setShowDetailmodal(true);
    }
    else {
      addtocart();
    }
  }

  //Function to add or decrease item
  const addtocart = () => {

    if (quantity >= max) {

    } else {
      setQuantity(quantity + 1);
      props.getValue({ id: props.id, quantity: (quantity + 1) });
    }

    if (T) {
      var myHeaders = new Headers();
      myHeaders.append("Cookie", "_lang=en")
      var formdata = new FormData();
      formdata.append("item_id", props.id);
      formdata.append("price", props.price);
      formdata.append("qty", "1")
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
      };
      fetch(urls.Add_Delete_Item + "?merchant_keys=" + MK + "&device_id=" + T + "&device_platform=web&transaction_type=pickup&lang=en&_=1644817151280&token=" + T, requestOptions)
        .then(response => response.json())
        .then(result => {
          if (result.code === 1) {
            toast.success(result.msg, {
              position: "top-center",
              autoClose: 400
            });
            Loadcart()
          }
        })
    }

    else {
      const currentItem = cart.filter((item) => {
        return item.price === props.price;
      });
      if (currentItem === 'undefined' || currentItem.length == 0) {
        // item is not present in cart yet
        const newItemToAdd = { item_id: props.id, photo: props.image, item_name: props.title, price: props.price, qty: 1, category_id: props.category_id, sort_id: cart.length + 1, size: size } //

        cart.push(newItemToAdd);
        localStorage.setItem('cart', JSON.stringify(cart));
        toast.success('item added', {
          position: "top-center",
          autoClose: 400
        });
        Loadcart();

      } else {
        // item is present
        const newCart = cart.filter((item) => {
          return item.item_id !== props.id;
        });
        newCart.push({ ...currentItem[0], qty: currentItem[0].qty + 1 });
        localStorage.setItem('cart', JSON.stringify(newCart));
        toast.success('item added', {
          position: "top-center",
          autoClose: 400
        });
        Loadcart();
      }
    }
  }

  // funtion to load item details
  const Itemdetails = () => {

    var myHeaders = new Headers();
    myHeaders.append("Cookie", "_lang=en");

    var formdata = new FormData();
    formdata.append("item_id", props.id);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    fetch(urls.ItemDetails + "?merchant_keys=" + MK, requestOptions)
      .then(response => response.json())
      .then(result => { setPrice(result.details.data.prices) })
    localStorage.setItem("id", props.id);
    setShowDetailmodal(true);
  }

  return (

    <div className={"p-3 border-bottom " + props.itemClass}>
      <Detailmodal show={showDetailmodal} onHide={hideDetailmodal} quantity={totalQuantity} />
      {quantity <= 0 ?
        <span className="float-right">
          <Button variant='outline-secondary' onClick={addhandler} size="sm">ADD</Button>
        </span>
        :
        <span className="count-number float-right">
          <Button variant="outline-secondary" onClick={Itemdetails} className="btn-sm left dec"> <Icofont icon="minus" /> </Button>
          <input className="count-number-input" type="text" value={totalQuantity} readOnly />
          <Button variant="outline-secondary" onClick={Itemdetails} className="btn-sm right inc"> <Icofont icon="icofont-plus" /> </Button>
        </span>
      }
      <Media>
        {/* {props.image ?
          <Image className={"mr-3 rounded-pill " + props.imageClass} src={props.image} alt={props.imageAlt} />
          :
          <div className="mr-3"><Icofont icon="ui-press" className={"text-danger1 food-item"} /></div>
        } */}
        <Media.Body>
          <div className='d-flex gap-2'>
            <img src={props.vegNonvegTag} alt='' style={{height: '1.2rem', width: 'auto'}}/>
            <Link className="mb-1 text-black" onClick={Itemdetails} >{props.title} {props.showBadge ? <Badge variant={props.badgeVariant}>{props.badgeText}</Badge> : ""}</Link>
          </div>
          <p
            className="text-gray mb-0 text-truncate-2-lines"
            title={props.subTitle}  // Show full subtitle in tooltip on hover
          >
            {props.subTitle || ''}
          </p>
          <p className="text-gray mb-0">{props.priceUnit}{props.price}</p>
        </Media.Body>
      </Media>
    </div>

  );
}

export default QuickBite;
