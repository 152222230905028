import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { Tab, Nav, Image, Badge } from "react-bootstrap";

import Icofont from "react-icofont";
import { GlobalContext } from "../GlobalState";
import GlobalContext_ from "../../GlobalContext";

const RestaurantInfo = () => {
  const { restaurantinfo, open } = useContext(GlobalContext_);

  return (
    <div id="restaurant-info" className="bg-white rounded shadow-sm p-4 mb-4">
      <div className="address-map float-right ml-5"></div>
      <h5 className="mb-4">{restaurantinfo?.merchant_name}</h5>
      <p className="mb-3">{restaurantinfo?.address}</p>
      <p className="mb-0 text-black">
        <Icofont icon="phone-circle text-primary mr-2" />
        {restaurantinfo?.contact_phone}
      </p>
      <Link
        className="mb-2"
        onClick={() =>
          (window.location = "mailto:" + restaurantinfo?.email_address)
        }
      >
        <Icofont icon="email text-primary mr-2" />
        {restaurantinfo?.email_address}
      </Link>
      {open
        ? open?.map((Open, index) => (
            <p className="mb-2 text-black" key={index}>
              <Icofont icon="clock-time text-primary mr-2" /> {Open.day},
              {Open.hours.replaceAll("&nbsp;", " ")}
              <Badge variant="success" className="ml-1">
                {" "}
                OPEN
              </Badge>
            </p>
          ))
        : ""}
      <hr className="clearfix" />
      <h5 className="mt-4 mb-4">More Info</h5>
      <p className="mb-3">{restaurantinfo?.information}</p>
      <p className="mb-3">{restaurantinfo?.cuisine}</p>
    </div>
  );
};

export default RestaurantInfo;
